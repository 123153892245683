import React from 'react'
import { Wrapper } from './Styled-components'

const Content = () => {
  return (
    <>
      <Wrapper>
        <h1> Privacy Policy | Suada LMS </h1>
        <p>Effective Date: 1st June 2022</p>
        <p>
          ‍This Privacy Policy covers the personal data we collect about data
          subjects and provides the information required by art. 13 of General
          Data Protection Regulation n. 679/2016, unless a different policy is
          displayed.
        </p>
        <p>
          “Suada”, “We” and “Us” refers to Suada and/or any of its other
          corporate affiliates, listed below in the “Contact us” section, when
          acting as Controller.
        </p>
        <p>
          We refer to our products, together with our services and websites as
          “Services” in this policy.
        </p>
        <p>
          This Privacy Policy describes how Suada collects and uses the personal
          information you provide. It also describes the choices available to
          you regarding the use of, your access to, and how to update and
          correct your personal information.
        </p>
        <p>
          Suada Corporation participates in and has certified its compliance
          with the EU-U.S. Privacy Shield Framework and the Swiss-U.S. Privacy
          Shield Framework. Suada Corporation is committed to subjecting all
          personal data received from European Union (EU) member countries and
          Switzerland, respectively, in reliance on each Privacy Shield
          Framework, to the Framework’s applicable Principles.
        </p>
        <p>
          Suada Corporation is responsible for the processing of personal data
          it receives, under each Privacy Shield Framework, and subsequently
          transfers to a third party acting as an agent on its behalf. Suada
          Corporation complies with the Privacy Shield Principles for all onward
          transfers of personal data from the EU and Switzerland, including the
          onward transfer liability provisions.
        </p>
        <p>
          With respect to personal data received or transferred pursuant to the
          Privacy Shield Frameworks, Suada Corporation is subject to the
          regulatory enforcement powers of the U.S. Federal Trade Commission. In
          certain situations, Suada Corporation may be required to disclose
          personal data in response to lawful requests by public authorities,
          including to meet national security or law enforcement requirements.
        </p>
        <p>
          Under certain conditions, more fully described on the Privacy Shield
          website, you may invoke binding arbitration when other dispute
          resolution procedures have been exhausted.
        </p>
        <h6>WHEN WE COLLECT INFORMATION ABOUT YOU:</h6>
        <p>
          We collect information about you when you provide it to us, when you
          use our Services, and when other sources provide it to us, as further
          described below.
        </p>
        <p>
          Account and Profile Information: We collect information about you when
          you register for an account, create or modify your profile, set
          preferences, sign-up for or make purchases through the Services.
        </p>
        <p>
          The content you provide through our products: We collect and store
          content you post, send, receive and share through the Services.
        </p>
        <p>
          The content you provide through our websites: We collect content that
          you submit to websites owned or operated by us when you may choose to
          fill up contact forms or when you participate in any interactive
          features, surveys, promotions, activities or events.
        </p>
        <p>
          Information you provide through our support channels: We collect
          information regarding a problem you are experiencing with the Services
          when you may choose to submit them to our customer support included in
          the Services.
        </p>
        <p>
          Payment Information: We collect certain payment and billing
          information when you register for certain paid Services.
        </p>
        <p>
          Information we collect automatically when you use the Services: We
          collect information about you when using our Services, including
          browsing our websites and taking certain actions within the Services.
        </p>
        <p>
          Your use of the Services: We keep track of certain information about
          you when you visit and interact with any of our Services.
        </p>
        <p>
          Device and Connection Information: We collect information about your
          computer, phone, tablet, or other devices you use to access the
          Services.
        </p>
        <p>
          Cookies and Other Tracking Technologies: We and our marketing
          partners, affiliates, or analytics or service providers use
          technologies such as cookies, beacons, tags, scripts, and other
          similar technologies, as further described below in the TRACKING
          TECHNOLOGIES section.
        </p>
        <p>
          Information we receive from other sources: We may receive information
          about you from other Service users, from third-party services, from
          our related companies, and from our business and channel partners.
        </p>

        <h6>WHAT INFORMATION WE COLLECT ABOUT YOU:</h6>
        <ul>
          <p>‍We collect the following personal information from you:</p>
          <li>
            • Contact Information such as name, business email address, phone
            number, billing information, physical address, skype information,
            the information you input in open text fields in help/contact us
            forms
          </li>
          <li>
            • You also have the option of adding a display name, profile photo,
            job title, and other details to your profile information to be
            displayed in our Services
          </li>
          <li>
            • Information about your business such as company name, industry,
            company size, company phone contacts and other Content information
            about you that you may choose to include. Examples of content we
            collect and store include: viewed content and training interactions.
          </li>
          <li>
            • Content also includes the files and links you upload to the
            Services
          </li>
          <li>
            • Contact information, a summary of the problem you are
            experiencing, and any other documentation, screenshots or
            information that would be helpful in resolving an issue submitted to
            our customer support included in the Services.
          </li>
          <li>
            • information about you when you visit and interact with any of our
            Services. This information includes the features you use; the links
            you click on; the type, size and filenames of attachments you upload
            to the Services; frequently used search terms; and how you interact
            with others on the Services.
          </li>
          <li>
            • Device information including your connection type and settings,
            information about your operating system, browser type, IP address,
            URLs of referring/exit pages, device identifiers, and crash data.
          </li>
        </ul>

        <h6>
          PURPOSES FOR WHICH WE PROCESS PERSONAL INFORMATION WE COLLECT AND
          CORRESPONDENT LEGAL BASIS:
        </h6>
        <p>
          We collect and process personal information for a variety of purposes,
          including:
        </p>
        <h6>PURPOSES</h6>
        <ul>
          <li>• Send you an order confirmation</li>
          <li>• Administer your account</li>
          <li>• To provide the Services and personalise your experience</li>
          <li>• Respond to demo requests</li>
          <li>• Respond to customer service requests</li>
        </ul>

        <h6>LEGAL BASIS</h6>
        <p>
          The processing of your personal data is necessary to perform our
          contract with you under applicable terms of use/service; where we have
          not entered into a contract with you we base the processing of your
          personal data on our legitimate interest to operate and administer our
          Services and to provide you with content you access and request
        </p>

        <h6>PURPOSES</h6>
        <ul>
          <li>• To communicate with you about the Services</li>
          <li>• Display content based upon your interests</li>
          <li>• Send you a newsletter</li>
          <li>• Send you marketing communications</li>
          <li>• LEGAL BASIS</li>
        </ul>
        <p>
          We base the processing of your personal data on our legitimate
          interest in conducting direct marketing or to the extent you have
          provided your prior consent
        </p>
        <h6>PURPOSES</h6>
        <ul>
          <li>
            • For research and development to troubleshoot and to identify
            trends, usage, activity patterns and areas for integration and
            improvement of the Service
          </li>
        </ul>

        <h6>LEGAL BASIS</h6>
        <ul>
          <li>
            We base the processing of your personal data on our legitimate
            interest in developing and improving the service offering, or where
            we seek your valid consent
          </li>
        </ul>

        <h6>PURPOSES</h6>
        <ul>
          <li>
            • For customer support to resolve technical issues, to respond to
            requests for assistance, to analyze crash information, and to repair
            and improve the Services
          </li>
        </ul>

        <h6>LEGAL BASIS</h6>
        <ul>
          <li>
            We process your personal data to perform our contract with you under
            applicable terms of use/service and to the extent it is necessary
            for our legitimate interest in fulfilling your requests and
            communicating with you
          </li>
        </ul>

        <h6>PURPOSES</h6>
        <ul>
          <li>
            • For safety and security to verify accounts and activity, to
            monitor suspicious or fraudulent activity and to identify violations
            of Service policies
          </li>
        </ul>

        <h6>LEGAL BASIS</h6>
        <ul>
          <li>
            We process your personal data to the extent it is necessary for our
            legitimate interest in promoting the safety and security of the
            Services and in protecting our rights and the rights of others
          </li>
        </ul>

        <h6>PURPOSES</h6>
        <ul>
          <li>• Complying with legal obligations</li>
          <li>
            • To protect our legitimate business interests and legal rights
          </li>
        </ul>

        <h6>LEGAL BASIS</h6>
        <ul>
          <li>
            We process your personal data when cooperating with public and
            government authorities, courts or regulators in accordance with our
            legal obligations under applicable laws to the extent this requires
            the processing or disclosure of personal data to protect our rights
            or is necessary for our legitimate interest in protecting against
            misuse or abuse of our Services, protecting personal property or
            safety, pursuing remedies available to us and limiting our damages,
            complying with judicial proceedings, court orders or legal processes
            or to respond to lawful requests
          </li>
        </ul>

        <h6>SHARING OF PERSONAL INFORMATION:</h6>
        <p>
          We will share your personal information with third parties only in the
          ways that are described in this privacy statement.
        </p>

        <h6>Service Providers</h6>
        <p>
          We may provide your personal information to companies that provide
          services to help us with our business activities such as processing
          your payments. These companies are authorized to use your personal
          information only as necessary to provide these services to us.
        </p>

        <h6>Legal</h6>
        <p>
          We may also disclose your personal information as required by law,
          such as to comply with a subpoena, bankruptcy proceedings, or similar
          legal process when we believe in good faith that disclosure is
          necessary to protect our rights, protect your safety or the safety of
          others, investigate fraud, or respond to a government request. If
          Suada is involved in a merger, acquisition, or sale of all or a
          portion of its assets, you will be notified via email and/or a
          prominent notice on our website, of any change in ownership, uses of
          your personal information, and choices you may have regarding your
          personal information. We may also disclose your personal information
          to any other third party with your prior consent.
        </p>

        <h6>OPT-OUT PREFERENCES:</h6>
        <p>
          ‍If you no longer want to receive marketing-related emails from us,
          you may opt out of receiving such emails by clicking the “unsubscribe”
          link at the bottom of any marketing email you receive from us. If you
          are having difficulty unsubscribing from our marketing emails using
          the above methods, please contact us directly at the email
          support@suada.com.
        </p>

        <h6>EXERCISING YOUR RIGHTS:</h6>
        <p>
          ‍You have the right to amend or update inaccurate or incomplete
          personal information, request deletion of your personal information,
          or request that we no longer use it. You may submit a request for
          access (i.e. request information on personal data collected, used,
          disclosed or processed by Suada), as well as a request for
          integration, rectification, or erasure, or object to our processing of
          your personal data. Upon request, Suada will provide you with
          information about whether we hold any of your personal information.
          Furthermore, you may also be able to exercise the following rights to
          restrict processing, data portability, and lodge a complaint with a
          data protection authority.
          <br />
          <br />
          In particular, you have the right to object and withdraw your consent,
          in whole or in part, to the collection, use, disclosure or processing
          of your personal data for purposes of dispatch of advertising
          material, direct selling or for the fulfillment of marketing surveys
          or commercial communication (e-mail).
          <br />
          <br />
          If you prefer that the processing of your personal data is carried out
          solely by means of traditional contact methods, you may object to the
          processing of your personal data by means of automated contact
          methods.
          <br />
          <br />
          In order to exercise your rights above and/or submit inquiries or
          complaints with regard to our processing of your personal data, you
          may send a request to Suada by writing to this email address
          support@suada.com. We will respond to your request within thirty (30)
          days.
        </p>

        <h6>SECURITY</h6>
        <p>
          The security of your personal information is important to us.
          <br />
          <br />
          We follow generally accepted security standards and we have adopted
          commercially reasonable security measures to protect your personal
          data against accidental or unlawful destruction, loss, alteration, or
          unauthorized disclosure or access. We may use third-party products and
          services to secure or store your information. For more information on
          where we store and how we secure your information, please see our
          compliance and security page.
          <br />
          <br />
          By using the Services or providing personal information to us, you
          agree that we can communicate with you electronically regarding
          security, privacy, and administrative issues relating to your use of
          the Services. Depending on where you live, you may have a legal right
          to receive written notice of a data privacy or security breach. If you
          have any reason to believe that your interactions with the Services
          are no longer secure, please notify us immediately at
          support@suada.com.
          <br />
          <br />
          We may, subject to applicable law, transfer your information outside
          the country where you are located and to where information protection
          standards may differ (e.g., your information may be stored on servers
          located in other jurisdictions). We will utilize appropriate
          safeguards governing the transfer and usage of your personal
          information.
          <br />
          <br />
          If you have any questions about the security of your personal
          information, you can contact us at support@suada.com.
        </p>

        <h6>HOW LONG WE KEEP INFORMATION:</h6>
        <p>
          ‍How long we keep the information we collect about you depends on the
          type of information, as described in further detail below. After such
          time, we will either delete your information or, if this is not
          possible (for example, because the information has been stored in
          backup archives), then we will securely store your information and
          isolate it from any further use until deletion is possible.
          <br />
          <br />
          Account information: We retain your account information for as long as
          your account is active and a reasonable period thereafter in case you
          decide to re-activate the Services. We also retain some of your
          information as necessary to comply with our legal obligations, to
          resolve disputes, to enforce our agreements, to support business
          operations, and to continue to develop and improve our Services. Where
          we retain information for Service improvement and development, we take
          steps to eliminate information that directly identifies you, and we
          only use the information to uncover collective insights about the use
          of our Services, not to specifically analyze personal characteristics
          about you.
          <br />
          <br />
          Information you share on the Services: If your account is deactivated
          or disabled, some of your information and the content you have
          provided will remain in order to allow your team members or other
          users to make full use of the Services.
          <br />
          <br />
          Managed accounts: If the Services are made available to you through an
          organization (e.g., your employer), we retain your information as long
          as required by the administrator of your account.
          <br />
          <br />
          Marketing information: If you have elected to receive marketing emails
          from us, we retain information about your marketing preferences for a
          reasonable period of time from the date you last expressed interest in
          our Services, such as when you last opened an email from us or ceased
          using your Suada account. We retain information derived from cookies
          and other tracking technologies for a reasonable period of time from
          the date such information was created.
        </p>
        <h6>DISCLOSURES SPECIFIC TO THE SUADA PLATFORM/SERVICE:</h6>
        <p>
          The use of information collected through our service shall be limited
          to the purpose of providing the service for which the Client has
          engaged Suada. Suada collects information under the direction of its
          Clients, and has no direct relationship with the individuals whose
          personal data it processes. If you are a customer of one of our
          Clients and would no longer like to be contacted by one of our Clients
          that use our service, please contact the Client that you interact with
          directly. We may transfer personal information to companies that help
          us provide our service. Transfers to subsequent third parties are
          covered by the service agreements with our Clients.
          <br />
          <br />
          Suada has no direct relationship with the individuals whose personal
          data it processes. An individual who seeks access, or who seeks to
          correct, amend, or delete inaccurate data should direct his query to
          the Suada’s Client (the data controller). If requested to remove data
          we will respond within a reasonable timeframe.
          <br />
          <br />
          We will retain personal data we process on behalf of our Clients for
          as long as needed to provide services to our Client. Suada will retain
          this personal information as necessary to comply with our legal
          obligations, resolve disputes, and enforce our agreements.
        </p>
        <h6>TRACKING TECHNOLOGIES</h6>
        <p>
          ‍We and our marketing partners, affiliates, or analytics or service
          providers, use technologies such as cookies, beacons, tags, scripts,
          and other similar technologies to analyze trends, administer the
          website, tracking users’ movements around the website, and to gather
          demographic information about our user base as a whole. We may receive
          reports based on the use of these technologies by these companies on
          an individual and aggregated basis.
          <br />
          <br />
          We use cookies for our shopping cart, to remember users’ settings
          (e.g. language preference), for authentication. Users can control the
          use of cookies at the individual browser level. If you reject cookies,
          you may still use our website, but your ability to use some features
          or areas of our website may be limited.
          <br />
          <br />
          As is true of most websites, we gather certain information
          automatically and store it in log files. This information may include
          Internet protocol (IP) addresses, browser type, internet service
          provider (ISP), referring/exit pages, operating system, date/time
          stamp, and/or clickstream data.
          <br />
          <br />
          We do not link this automatically-collected data to other information
          we collect about you.
          <br />
          <br />
          We partner with a third party to either display advertising on our
          website or to manage our advertising on other sites. Our third party
          partner may use technologies such as cookies to gather information
          about your activities on this website and other sites in order to
          provide you advertising based upon your browsing activities and
          interests. If you wish to not have this information used for the
          purpose of serving you interest-based ads, you may opt-out by clicking
          here (or if located in the European Union click here). Please note
          this does not opt you out of being served ads. You will continue to
          receive generic ads.
          <br />
          <br />
          You can find more information about cookies reading our cookie policy.
        </p>
        <h6>SOCIAL MEDIA WIDGETS</h6>
        <p>
          ‍Our website includes Social Media Features, such as the Facebook Like
          button, and Widgets, such as the Share This button or interactive
          mini-programs that run on our website. These Features may collect your
          Internet protocol address, which page you are visiting on our website,
          and may set a cookie to enable the Feature to function properly.
          Social Media Features and Widgets are either hosted by a third party
          or hosted directly on our website. Your interactions with these
          Features are governed by the privacy statement of the company
          providing it.
        </p>

        <h6>‍LINKS TO OTHER SITES</h6>
        <p>
          ‍From time to time, we may include links on the Sites to third-party
          websites. Please pay attention when you connect to these websites and
          read their terms and conditions of use and privacy policies carefully.
          We do not control or monitor such websites or their web content. This
          Privacy Statement does not apply to any third-party websites and we
          will are not responsible for the content, privacy policies, or
          processing of your Personal data while you are visiting any
          third-party websites.
        </p>
        <h6>
          AGAIN, PLEASE NOTE THAT THIS PRIVACY STATEMENT DOES NOT COVER THE
          COLLECTION AND USE OF INFORMATION BY SUCH THIRD-PARTY WEBSITES AND
          ADVERTISERS.
        </h6>
        <h6>CHANGES TO THIS PRIVACY POLICY:</h6>
        <p>
          We reserve the right to amend all or part of our Privacy Statements
          from time to time. The version published on the Sites is the version
          currently in force. Changes to our Privacy Statements are communicated
          by placing a notice on the Sites stating “Revised Privacy
          Statement(s).” Changes to our Privacy Statements will be effective
          immediately once published on the Sites unless otherwise noted. Your
          use of the Sites following any amendments indicates your consent to
          the practices described in the revised Privacy Statements. If we make
          any material changes we will notify you by email (sent to the e-mail
          address specified in your account) or by means of a notice on this
          website prior to the change becoming effective. We invite you to
          periodically review our Privacy Statements to be informed of any
          relevant changes, especially before providing any data to us.
        </p>
      </Wrapper>
    </>
  )
}

export default Content
