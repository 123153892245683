import styled from 'styled-components'

export const BannerNormalizer = styled.div`
  width: 100%;
  height: 95px;
`

export const Wrapper = styled.section`
  padding: 34px 165px 120px 165px;
  display: flex;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.wl}) {
    padding: 34px 100px 120px 100px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
    padding: 34px 50px 120px 50px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    padding: 34px 20px 60px 20px;
  }
`

export const StyledForm = styled.form`
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  width: 70%;
  max-width: 800px;
  padding: 32px 95px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.breakpoints.wl}) {
    max-width: unset;
    width: 100%;
  }

  .custom-button {
    width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    padding-inline: 20px;
    .custom-button {
      width: 100%;
    }
  }
`
